<template>
  <div>
    <!-- Start Header Area -->
    <header class="default-header">
      <div class="container">
        <div class="header-wrap">
          <div class="header-top d-flex justify-content-between align-items-center">
            <div class="logo">
              <a href="https://donations.vanex.ly"><img height="64" src="img/logo.svg" alt="" /></a>
            </div>
          </div>
        </div>
      </div>
    </header>
    <!-- End Header Area -->

    <!-- start banner Area -->
    <section class="banner-area relative" id="home">
      <div class="overlay overlay-bg"></div>
      <div class="container">
        <div class="row fullscreen align-items-center justify-content-start" style="height: 915px">
          <div class="banner-content text-right col-lg-9 col-md-12">
            <h1>
              انضم إلى حملتنا <br />
              للمساهمة في إغاثة المتضررين في ببلادنا!
            </h1>
            <a href="#donate" class="head-btn btn text-uppercase">تبرع الأن</a>
          </div>
        </div>
      </div>
    </section>
    <!-- End banner Area -->

    <!-- Start callto Area -->
    <section class="callto-area relative">
      <div class="container">
        <div class="row d-flex callto-wrap justify-content-between pt-40 pb-40">
          <h3 class="text-white">
            انضم إلى حملتنا للمساهمة في إغاثة المنطقة الشرقية ببلادنا! معًا
            نستطيع تحقيق الفرق ومساعدة المتضررين نساهم في توصيل دعمكم لنجعل
            هذه المبادرة ناجحة. مع تفاقم الأضرار الناجمة عن الفيضانات الأخيرة،
            نعمل جاهدين على توفير المساعدة والإغاثة للأسر المتضررة.
          </h3>
        </div>
      </div>
    </section>
    <!-- End callto Area -->
    <section class="section services-section" id="services">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="section-title">
              <h2>كيف يمكنك المساهمة:</h2>
            </div>
          </div>
        </div>
        <div class="row">
          <!-- feaure box -->
          <div class="col-sm-6 col-lg-4">
            <div class="feature-box-1" style="height: 150px !important">
              <div class="feature-content">
                <h5 style="margin-bottom: 8px">التبرع المالي</h5>
                <p>يمكنك تقديم تبرع مالي بأي مبلغ تختاره. كل قرش يحسب.</p>
              </div>
            </div>
          </div>
          <!-- / -->
          <!-- feaure box -->
          <div class="col-sm-6 col-lg-4">
            <div class="feature-box-1" style="height: 150px !important">
              <div class="feature-content">
                <h5 style="margin-bottom: 8px">التبرع العيني</h5>
                <p>تقديم أي إحتياجات عينية يمكن ان يستفيد بها المتضررين.</p>
              </div>
            </div>
          </div>
          <!-- / -->
          <!-- feaure box -->
          <div class="col-sm-6 col-lg-4">
            <div class="feature-box-1" style="height: 150px !important">
              <div class="feature-content">
                <h5 style="margin-bottom: 8px">النشر والمشاركة</h5>
                <p>
                  ساعدنا في نشر هذه الحملة على وسائل التواصل الاجتماعي
                  ومشاركتها مع أصدقائك وعائلتك. كل مساهمة تهم. .
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Start project Area -->
    <section class="project-area section-gap" id="donate">
      <div class="container">
        <div class="row d-flex">
          <div class="section-title">
            <h2>معًا نستطيع تحقيق الفرق ومساعدة المتضررين</h2>
            <p>
              انضم إلينا اليوم وساهم في إحداث تأثير إيجابي حقيقي. معًا،
              يمكننا جعل الفرق!
            </p>
          </div>
        </div>
        <div class="row d-flex justify-content-center">
          <div class="col-lg-12">
            <form dir="rtl" class="booking-form" id="myForm" @submit.prevent="post">
              <div class="row">
                <div class="col-lg-6 d-flex flex-column mb-4">
                  <label for="type">نوع التبرع</label>
                  <select v-model="selectedDonationType" id="type" class="app-select form-control" required>
                    <option v-for="donationType in donationsTypes" :key="donationType.value"
                      :value="donationType.value">{{donationType.label}} </option>
                  </select>
                </div>
                <div class="col-lg-6 d-flex flex-column mb-4">
                  <label for="fname">اسم المتبرع</label>
                  <input name="fname" v-model="reciever" placeholder="ادخل اسم المتبرع" class="form-control" type="text"
                    required>
                </div>
                <div class="col-lg-6 d-flex flex-column mb-4">
                  <label for="phone">رقم الهاتف</label>
                  <input name="phone" v-model="phone" placeholder="0910000000" class="form-control" type="phone"
                    required>
                </div>
                <div class="col-lg-6 d-flex flex-column mb-4">
                  <label for="phone_b">رقم الإحتياطي</label>
                  <input name="phone_b" v-model="phone_b" placeholder="0910000000" class="form-control" type="phone"
                    required>
                </div>
                <div class="col-lg-6 d-flex flex-column mb-4">
                  <label for="city">المدينة</label>
                  <select @change="getSubCities()" v-model="city" id="city" class="app-select form-control" required>
                    <option :value="city" v-for="city in cities" :key="city.id">{{city.name}}</option>
                  </select>
                </div>
                <div v-if="subCities.length>0" class="col-lg-6 d-flex flex-column mb-4">
                  <label for="subcity">المنطقة</label>
                  <select v-model="address_child" id="subcity" class="app-select form-control" required>
                    <option :value="subCity.id" v-for="subCity in subCities" :key="subCity.id">{{subCity.name}}</option>
                  </select>
                </div>
                <div class="col-lg-12 d-flex flex-column mb-4">
                  <label for="address">العنوان</label>
                  <input name="address" v-model="address" placeholder="ادخل العنوان التفصيلي للمتبرع"
                    class="form-control" type="text" required>
                </div>
                <div class="col-lg-6 d-flex flex-column mb-4">
                  <label for="description">وصف التبرع</label>
                  <input name="description" v-model="description" placeholder="بماذا تتبرع؟" class="form-control">
                </div>
                <div v-if="selectedDonationType == 1" class="col-lg-6 d-flex flex-column mb-4">
                  <label for="type">القيمة المالية</label>
                  <input name="price" v-model="price" placeholder="ادخل القيمة المالية" class="form-control"
                    type="number" required>
                </div>

                <div class="col-lg-12 d-flex justify-content-end send-btn">
                  <button class="submit-btn primary-btn mt-20 text-uppercase ">ارسال التبرع<span
                      class="lnr lnr-arrow-left"></span></button>
                </div>

                <div class="alert-msg"></div>
              </div>
            </form>
          </div>
        </div>

      </div>
    </section>

    <!-- start footer Area -->
    <footer class="footer-area section-gap">
      <div class="container">
        <div class="row d-flex flex-column mb-4 justify-content-center">
          <p class="footer-text m-0">
            <a href="https://vanex.ly" target="_blank">VANEX</a> حقوق النشر
            &copy;

            {{new Date().getFullYear()}}

            جميع الحقوق محفوظة
          </p>
        </div>
      </div>
    </footer>

  </div>
</template>

<script>
  import axios from 'axios'
  import swal from 'sweetalert';

  export default {
    name: 'App',
    components: {},
    data() {
      return {
        token: '169705|NaLAP12SbtqekZPWq74ihm1vuamdcogxxNpiIDGY',
        // من المتبرع
        reciever: null,
        phone: null,
        phone_b: null,
        address: null,
        city: null,
        address_child: null,
        price: 0,
        // مني
        description: null,
        type: 1,
        qty: 0,
        leangh: 35,
        width: 35,
        height: 35,
        breakable: false,
        receipt_money: null,
        measuring_is_allowed: false,
        storage_subscription: null,
        inspection_allowed: false,
        heat_intolerance: false,
        casing: false,
        paid_by: "market",
        extra_size_by: "market",
        commission_by: "market",
        save_address: false,
        payment_methode: "cash",
        package_sub_type: 6,
        type_id: 1,
        subCities: [],
        cities: [{
            "id": 1,
            "name": "طرابلس",
            "price": 10,
            "branch": 1,
            "locations": [{
                "id": 70,
                "name": "السياحية",
                "parent_city": 1,
                "price": 10
              },
              {
                "id": 71,
                "name": "حي الأندلس",
                "parent_city": 1,
                "price": 10
              },
              {
                "id": 72,
                "name": "وسط المدينة",
                "parent_city": 1,
                "price": 10
              },
              {
                "id": 73,
                "name": "الظهرة",
                "parent_city": 1,
                "price": 10
              },
              {
                "id": 74,
                "name": "زاوية الدهماني",
                "parent_city": 1,
                "price": 10
              },
              {
                "id": 75,
                "name": "النوفليين",
                "parent_city": 1,
                "price": 10
              },
              {
                "id": 76,
                "name": "بن عاشور",
                "parent_city": 1,
                "price": 10
              },
              {
                "id": 77,
                "name": "شارع الجرابة",
                "parent_city": 1,
                "price": 10
              },
              {
                "id": 78,
                "name": "شارع الزاوية",
                "parent_city": 1,
                "price": 10
              },
              {
                "id": 79,
                "name": "شارع الجمهورية",
                "parent_city": 1,
                "price": 10
              },
              {
                "id": 80,
                "name": "شارع النصر",
                "parent_city": 1,
                "price": 10
              },
              {
                "id": 81,
                "name": "باب بن غشير",
                "parent_city": 1,
                "price": 10
              },
              {
                "id": 82,
                "name": "قرقارش",
                "parent_city": 1,
                "price": 10
              },
              {
                "id": 83,
                "name": "غوط الشعال",
                "parent_city": 1,
                "price": 10
              },
              {
                "id": 84,
                "name": "السراج",
                "parent_city": 1,
                "price": 10
              },
              {
                "id": 85,
                "name": "الدريبي",
                "parent_city": 1,
                "price": 10
              },
              {
                "id": 86,
                "name": "قرجي",
                "parent_city": 1,
                "price": 10
              },
              {
                "id": 87,
                "name": "قدح",
                "parent_city": 1,
                "price": 10
              },
              {
                "id": 88,
                "name": "أبوسليم",
                "parent_city": 1,
                "price": 10
              },
              {
                "id": 89,
                "name": "الرياضية",
                "parent_city": 1,
                "price": 10
              },
              {
                "id": 90,
                "name": "الفلاح",
                "parent_city": 1,
                "price": 10
              },
              {
                "id": 91,
                "name": "غرغور",
                "parent_city": 1,
                "price": 10
              },
              {
                "id": 92,
                "name": "حي الزهور",
                "parent_city": 1,
                "price": 10
              },
              {
                "id": 93,
                "name": "طريق المطار",
                "parent_city": 1,
                "price": 10
              },
              {
                "id": 94,
                "name": "الهضبة",
                "parent_city": 1,
                "price": 10
              },
              {
                "id": 95,
                "name": "صلاح الدين",
                "parent_city": 1,
                "price": 15
              },
              {
                "id": 96,
                "name": "خلة الفرجان",
                "parent_city": 1,
                "price": 15
              },
              {
                "id": 97,
                "name": "طريق الشوك",
                "parent_city": 1,
                "price": 10
              },
              {
                "id": 98,
                "name": "الفرناج",
                "parent_city": 1,
                "price": 10
              },
              {
                "id": 99,
                "name": "الحي الجامعي",
                "parent_city": 1,
                "price": 10
              },
              {
                "id": 100,
                "name": "سيدي المصري",
                "parent_city": 1,
                "price": 10
              },
              {
                "id": 101,
                "name": "راس حسن",
                "parent_city": 1,
                "price": 10
              },
              {
                "id": 102,
                "name": "زناتة",
                "parent_city": 1,
                "price": 10
              },
              {
                "id": 103,
                "name": "جامع الصقع",
                "parent_city": 1,
                "price": 10
              },
              {
                "id": 104,
                "name": "الهاني",
                "parent_city": 1,
                "price": 10
              },
              {
                "id": 105,
                "name": "عرادة",
                "parent_city": 1,
                "price": 10
              },
              {
                "id": 106,
                "name": "الحشان",
                "parent_city": 1,
                "price": 10
              },
              {
                "id": 107,
                "name": "سوق الجمعة",
                "parent_city": 1,
                "price": 10
              },
              {
                "id": 108,
                "name": "كشلاف",
                "parent_city": 1,
                "price": 10
              },
              {
                "id": 109,
                "name": "الغرارات",
                "parent_city": 1,
                "price": 10
              },
              {
                "id": 110,
                "name": "بوستة",
                "parent_city": 1,
                "price": 10
              },
              {
                "id": 111,
                "name": "طريق الشط",
                "parent_city": 1,
                "price": 10
              },
              {
                "id": 112,
                "name": "معيتيقة",
                "parent_city": 1,
                "price": 10
              },
              {
                "id": 113,
                "name": "11 يونيو",
                "parent_city": 1,
                "price": 10
              },
              {
                "id": 114,
                "name": "السبعة",
                "parent_city": 1,
                "price": 10
              },
              {
                "id": 115,
                "name": "طريق المشتل",
                "parent_city": 1,
                "price": 15
              },
              {
                "id": 116,
                "name": "حي دمشق",
                "parent_city": 1,
                "price": 10
              },
              {
                "id": 117,
                "name": "ولي العهد",
                "parent_city": 1,
                "price": 10
              },
              {
                "id": 118,
                "name": "السدرة",
                "parent_city": 1,
                "price": 10
              },
              {
                "id": 119,
                "name": "عين زارة",
                "parent_city": 1,
                "price": 15
              },
              {
                "id": 147,
                "name": "طريق السور",
                "parent_city": 1,
                "price": 10
              },
              {
                "id": 148,
                "name": "بوابة الجبس",
                "parent_city": 1,
                "price": 10
              },
              {
                "id": 149,
                "name": "غابة النصر",
                "parent_city": 1,
                "price": 10
              },
              {
                "id": 159,
                "name": "شارع الصريم",
                "parent_city": 1,
                "price": 10
              },
              {
                "id": 584,
                "name": "جنزور",
                "parent_city": 1,
                "price": 15
              },
              {
                "id": 698,
                "name": "فشلوم",
                "parent_city": 1,
                "price": 10
              },
              {
                "id": 700,
                "name": "المنصورة",
                "parent_city": 1,
                "price": 10
              },
              {
                "id": 702,
                "name": "عمر المختار",
                "parent_city": 1,
                "price": 10
              }
            ],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 2,
            "name": "بنغازي",
            "price": 30,
            "branch": 1,
            "locations": [{
                "id": 120,
                "name": "وسط المدينة",
                "parent_city": 2,
                "price": 30
              },
              {
                "id": 121,
                "name": "الصابري",
                "parent_city": 2,
                "price": 30
              },
              {
                "id": 122,
                "name": "راس عبيدة",
                "parent_city": 2,
                "price": 30
              },
              {
                "id": 123,
                "name": "بوزغيبة",
                "parent_city": 2,
                "price": 30
              },
              {
                "id": 124,
                "name": "حي السلام",
                "parent_city": 2,
                "price": 30
              },
              {
                "id": 125,
                "name": "شبنة",
                "parent_city": 2,
                "price": 30
              },
              {
                "id": 126,
                "name": "طريق النهر",
                "parent_city": 2,
                "price": 30
              },
              {
                "id": 127,
                "name": "الماجوري",
                "parent_city": 2,
                "price": 30
              },
              {
                "id": 128,
                "name": "سيدي حسين",
                "parent_city": 2,
                "price": 30
              },
              {
                "id": 129,
                "name": "البركة",
                "parent_city": 2,
                "price": 30
              },
              {
                "id": 130,
                "name": "الحدائق",
                "parent_city": 2,
                "price": 30
              },
              {
                "id": 131,
                "name": "أرض لملوم",
                "parent_city": 2,
                "price": 30
              },
              {
                "id": 132,
                "name": "السلماني",
                "parent_city": 2,
                "price": 30
              },
              {
                "id": 133,
                "name": "الوحيشي",
                "parent_city": 2,
                "price": 30
              },
              {
                "id": 134,
                "name": "سيدي يونس",
                "parent_city": 2,
                "price": 30
              },
              {
                "id": 135,
                "name": "ارض الحراسة",
                "parent_city": 2,
                "price": 30
              },
              {
                "id": 136,
                "name": "طابلينو",
                "parent_city": 2,
                "price": 30
              },
              {
                "id": 137,
                "name": "الرحبة",
                "parent_city": 2,
                "price": 30
              },
              {
                "id": 138,
                "name": "بلعون",
                "parent_city": 2,
                "price": 30
              },
              {
                "id": 139,
                "name": "بوهديمة",
                "parent_city": 2,
                "price": 30
              },
              {
                "id": 140,
                "name": "الليثي",
                "parent_city": 2,
                "price": 30
              },
              {
                "id": 141,
                "name": "الفويهات",
                "parent_city": 2,
                "price": 30
              },
              {
                "id": 142,
                "name": "سوق الفحم",
                "parent_city": 2,
                "price": 30
              },
              {
                "id": 143,
                "name": "بوعطني",
                "parent_city": 2,
                "price": 30
              },
              {
                "id": 144,
                "name": "المساكن",
                "parent_city": 2,
                "price": 30
              },
              {
                "id": 145,
                "name": "قاريونس",
                "parent_city": 2,
                "price": 30
              },
              {
                "id": 146,
                "name": "قمينس",
                "parent_city": 2,
                "price": 30
              },
              {
                "id": 150,
                "name": "الكويفية",
                "parent_city": 2,
                "price": 30
              },
              {
                "id": 151,
                "name": "سلوق",
                "parent_city": 2,
                "price": 15
              },
              {
                "id": 152,
                "name": "بنينا",
                "parent_city": 2,
                "price": 35
              },
              {
                "id": 153,
                "name": "قنفوذة",
                "parent_city": 2,
                "price": 30
              },
              {
                "id": 154,
                "name": "سيدي خليفة",
                "parent_city": 2,
                "price": 30
              },
              {
                "id": 155,
                "name": "الرجمة",
                "parent_city": 2,
                "price": 30
              },
              {
                "id": 156,
                "name": "الهواري",
                "parent_city": 2,
                "price": 30
              },
              {
                "id": 157,
                "name": "الفعكات",
                "parent_city": 2,
                "price": 30
              },
              {
                "id": 158,
                "name": "بوهادي",
                "parent_city": 2,
                "price": 30
              },
              {
                "id": 160,
                "name": "الكيش",
                "parent_city": 2,
                "price": 30
              },
              {
                "id": 161,
                "name": "دقادوستا",
                "parent_city": 2,
                "price": 30
              },
              {
                "id": 317,
                "name": "القوارشة",
                "parent_city": 2,
                "price": 30
              },
              {
                "id": 318,
                "name": "شارع فينيسيا",
                "parent_city": 2,
                "price": 30
              },
              {
                "id": 335,
                "name": "ثاكنس",
                "parent_city": 2,
                "price": 35
              },
              {
                "id": 338,
                "name": "طلميثة",
                "parent_city": 2,
                "price": 40
              },
              {
                "id": 339,
                "name": "توكرة",
                "parent_city": 2,
                "price": 35
              },
              {
                "id": 342,
                "name": "قمينس",
                "parent_city": 2,
                "price": 35
              },
              {
                "id": 343,
                "name": "الرجمة",
                "parent_city": 2,
                "price": 35
              },
              {
                "id": 344,
                "name": "الابيار",
                "parent_city": 2,
                "price": 35
              },
              {
                "id": 345,
                "name": "سلوق",
                "parent_city": 2,
                "price": 35
              },
              {
                "id": 673,
                "name": "جردينة",
                "parent_city": 2,
                "price": 35
              },
              {
                "id": 675,
                "name": "المقزحة",
                "parent_city": 2,
                "price": 35
              },
              {
                "id": 677,
                "name": "بو مريم",
                "parent_city": 2,
                "price": 35
              }
            ],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 3,
            "name": "سبها",
            "price": 30,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 4,
            "name": "مصراتة",
            "price": 20,
            "branch": 1,
            "locations": [{
                "id": 359,
                "name": "شارع طرابلس",
                "parent_city": 4,
                "price": 20
              },
              {
                "id": 360,
                "name": "شارع بنغازي",
                "parent_city": 4,
                "price": 20
              },
              {
                "id": 361,
                "name": "شارع الهلال",
                "parent_city": 4,
                "price": 20
              },
              {
                "id": 362,
                "name": "الجامع العالي",
                "parent_city": 4,
                "price": 20
              },
              {
                "id": 363,
                "name": "شارع سناء محيدلي",
                "parent_city": 4,
                "price": 20
              },
              {
                "id": 364,
                "name": "شارع طرابلس",
                "parent_city": 4,
                "price": 20
              },
              {
                "id": 365,
                "name": "شارع بنغازي",
                "parent_city": 4,
                "price": 20
              },
              {
                "id": 366,
                "name": "شارع الهلال",
                "parent_city": 4,
                "price": 20
              },
              {
                "id": 367,
                "name": "الجامع العالي",
                "parent_city": 4,
                "price": 20
              },
              {
                "id": 368,
                "name": "شارع سناء محيدلي",
                "parent_city": 4,
                "price": 20
              },
              {
                "id": 369,
                "name": "شارع الاذاعة",
                "parent_city": 4,
                "price": 20
              },
              {
                "id": 370,
                "name": "المقاوبة",
                "parent_city": 4,
                "price": 20
              },
              {
                "id": 371,
                "name": "شارع مالطا",
                "parent_city": 4,
                "price": 20
              },
              {
                "id": 372,
                "name": "جزيرة النهر",
                "parent_city": 4,
                "price": 20
              },
              {
                "id": 373,
                "name": "شارع المطبات",
                "parent_city": 4,
                "price": 20
              },
              {
                "id": 374,
                "name": "الشواهدة",
                "parent_city": 4,
                "price": 20
              },
              {
                "id": 375,
                "name": "الجزيرة",
                "parent_city": 4,
                "price": 20
              },
              {
                "id": 376,
                "name": "إقزير",
                "parent_city": 4,
                "price": 20
              },
              {
                "id": 377,
                "name": "كرزاز",
                "parent_city": 4,
                "price": 20
              },
              {
                "id": 378,
                "name": "الغيران",
                "parent_city": 4,
                "price": 20
              },
              {
                "id": 379,
                "name": "طريق المطار",
                "parent_city": 4,
                "price": 20
              },
              {
                "id": 380,
                "name": "طمينة",
                "parent_city": 4,
                "price": 20
              },
              {
                "id": 381,
                "name": "الكراريم",
                "parent_city": 4,
                "price": 20
              },
              {
                "id": 382,
                "name": "زاوية المحجوب",
                "parent_city": 4,
                "price": 20
              },
              {
                "id": 383,
                "name": "قصر حمد",
                "parent_city": 4,
                "price": 20
              },
              {
                "id": 384,
                "name": "الثقيل",
                "parent_city": 4,
                "price": 20
              },
              {
                "id": 385,
                "name": "الجهانات",
                "parent_city": 4,
                "price": 20
              },
              {
                "id": 386,
                "name": "الزوابى",
                "parent_city": 4,
                "price": 20
              },
              {
                "id": 387,
                "name": "الشراكسة",
                "parent_city": 4,
                "price": 20
              },
              {
                "id": 388,
                "name": "الكراوده",
                "parent_city": 4,
                "price": 20
              },
              {
                "id": 389,
                "name": "القدارية",
                "parent_city": 4,
                "price": 20
              },
              {
                "id": 390,
                "name": "الحيارشة",
                "parent_city": 4,
                "price": 20
              },
              {
                "id": 391,
                "name": "السواوة",
                "parent_city": 4,
                "price": 20
              },
              {
                "id": 392,
                "name": "بوروية",
                "parent_city": 4,
                "price": 20
              },
              {
                "id": 393,
                "name": "السِكت",
                "parent_city": 4,
                "price": 20
              },
              {
                "id": 394,
                "name": "الرعيضات",
                "parent_city": 4,
                "price": 20
              },
              {
                "id": 395,
                "name": "السكيرات",
                "parent_city": 4,
                "price": 20
              },
              {
                "id": 396,
                "name": "الهباره",
                "parent_city": 4,
                "price": 20
              },
              {
                "id": 397,
                "name": "الملايطة",
                "parent_city": 4,
                "price": 20
              },
              {
                "id": 398,
                "name": "الزروق",
                "parent_city": 4,
                "price": 20
              },
              {
                "id": 399,
                "name": "الرملة",
                "parent_city": 4,
                "price": 20
              },
              {
                "id": 400,
                "name": "قرارة",
                "parent_city": 4,
                "price": 20
              },
              {
                "id": 401,
                "name": "يدر",
                "parent_city": 4,
                "price": 20
              },
              {
                "id": 402,
                "name": "راس علي",
                "parent_city": 4,
                "price": 20
              },
              {
                "id": 403,
                "name": "المنقوش",
                "parent_city": 4,
                "price": 20
              },
              {
                "id": 404,
                "name": "عباد",
                "parent_city": 4,
                "price": 20
              },
              {
                "id": 405,
                "name": "الدافنية",
                "parent_city": 4,
                "price": 20
              },
              {
                "id": 406,
                "name": "زمورة",
                "parent_city": 4,
                "price": 20
              },
              {
                "id": 407,
                "name": "شارع بازينة",
                "parent_city": 4,
                "price": 20
              },
              {
                "id": 408,
                "name": "شارع سعدون",
                "parent_city": 4,
                "price": 20
              },
              {
                "id": 409,
                "name": "المقاصبة",
                "parent_city": 4,
                "price": 20
              },
              {
                "id": 410,
                "name": "الصوالح",
                "parent_city": 4,
                "price": 20
              },
              {
                "id": 411,
                "name": "راس السايح",
                "parent_city": 4,
                "price": 20
              },
              {
                "id": 412,
                "name": "راس علي",
                "parent_city": 4,
                "price": 20
              },
              {
                "id": 413,
                "name": "راس فريدغ",
                "parent_city": 4,
                "price": 20
              },
              {
                "id": 414,
                "name": "راس التوتة",
                "parent_city": 4,
                "price": 20
              },
              {
                "id": 415,
                "name": "شارع الدم",
                "parent_city": 4,
                "price": 20
              },
              {
                "id": 416,
                "name": "الخروبة",
                "parent_city": 4,
                "price": 20
              },
              {
                "id": 417,
                "name": "طريق رقم 8",
                "parent_city": 4,
                "price": 20
              },
              {
                "id": 418,
                "name": "دائري إقزير",
                "parent_city": 4,
                "price": 20
              },
              {
                "id": 419,
                "name": "طريق البحر",
                "parent_city": 4,
                "price": 20
              },
              {
                "id": 420,
                "name": "الرويسات",
                "parent_city": 4,
                "price": 20
              },
              {
                "id": 421,
                "name": "زريق",
                "parent_city": 4,
                "price": 20
              },
              {
                "id": 741,
                "name": "السكت",
                "parent_city": 4,
                "price": 20
              },
              {
                "id": 747,
                "name": "المغدر",
                "parent_city": 4,
                "price": 20
              },
              {
                "id": 753,
                "name": "السواطي",
                "parent_city": 4,
                "price": 20
              },
              {
                "id": 756,
                "name": "سور سعود",
                "parent_city": 4,
                "price": 20
              },
              {
                "id": 767,
                "name": "إزريق",
                "parent_city": 4,
                "price": 20
              },
              {
                "id": 772,
                "name": "النباك",
                "parent_city": 4,
                "price": 20
              },
              {
                "id": 774,
                "name": "أولاد بعيو",
                "parent_city": 4,
                "price": 20
              }
            ],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 5,
            "name": "الزاوية",
            "price": 20,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 6,
            "name": "جنزور",
            "price": 15,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 7,
            "name": "صرمان",
            "price": 25,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 8,
            "name": "صبراتة",
            "price": 25,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 9,
            "name": "العجيلات",
            "price": 30,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 10,
            "name": "زوارة",
            "price": 30,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 11,
            "name": "الجميل",
            "price": 35,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 12,
            "name": "رقدالين",
            "price": 35,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 13,
            "name": "زلطن",
            "price": 35,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 14,
            "name": "تاجوراء",
            "price": 20,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 15,
            "name": "القره بوللي",
            "price": 20,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 16,
            "name": "قصر الاخيار",
            "price": 20,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 18,
            "name": "الخمس",
            "price": 20,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 19,
            "name": "زليتن",
            "price": 20,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 20,
            "name": "بن جواد",
            "price": 35,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 21,
            "name": "بن وليد",
            "price": 25,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 22,
            "name": "ترهونة",
            "price": 25,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 23,
            "name": "سرت",
            "price": 30,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 24,
            "name": "راس لانوف",
            "price": 35,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 25,
            "name": "البريقة",
            "price": 35,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 26,
            "name": "إجدابيا",
            "price": 30,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 27,
            "name": "المرج",
            "price": 35,
            "branch": 1,
            "locations": [{
                "id": 615,
                "name": "دريانة",
                "parent_city": 27,
                "price": 35
              },
              {
                "id": 617,
                "name": "المدني",
                "parent_city": 27,
                "price": 35
              },
              {
                "id": 619,
                "name": "توكرة",
                "parent_city": 27,
                "price": 35
              },
              {
                "id": 621,
                "name": "برسس",
                "parent_city": 27,
                "price": 35
              },
              {
                "id": 623,
                "name": "سي بوزيد",
                "parent_city": 27,
                "price": 35
              },
              {
                "id": 625,
                "name": "طلميثة",
                "parent_city": 27,
                "price": 35
              },
              {
                "id": 627,
                "name": "تاكنس",
                "parent_city": 27,
                "price": 35
              },
              {
                "id": 629,
                "name": "جردس",
                "parent_city": 27,
                "price": 35
              },
              {
                "id": 631,
                "name": "البنية",
                "parent_city": 27,
                "price": 35
              },
              {
                "id": 633,
                "name": "المليطانية",
                "parent_city": 27,
                "price": 35
              },
              {
                "id": 637,
                "name": "مدور الزيتون",
                "parent_city": 27,
                "price": 35
              },
              {
                "id": 639,
                "name": "البياضة",
                "parent_city": 27,
                "price": 35
              },
              {
                "id": 641,
                "name": "بطة",
                "parent_city": 27,
                "price": 35
              },
              {
                "id": 643,
                "name": "أبو ترابة",
                "parent_city": 27,
                "price": 35
              },
              {
                "id": 782,
                "name": "المرج الوسط",
                "parent_city": 27,
                "price": 35
              }
            ],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 28,
            "name": "البيضاء",
            "price": 35,
            "branch": 1,
            "locations": [{
                "id": 334,
                "name": "مسة",
                "parent_city": 28,
                "price": 35
              },
              {
                "id": 336,
                "name": "الغريقة",
                "parent_city": 28,
                "price": 35
              },
              {
                "id": 635,
                "name": "مراوة",
                "parent_city": 28,
                "price": 35
              },
              {
                "id": 707,
                "name": "مسة",
                "parent_city": 28,
                "price": 35
              },
              {
                "id": 710,
                "name": "الغريقة",
                "parent_city": 28,
                "price": 35
              },
              {
                "id": 713,
                "name": "البيضاء الوسط",
                "parent_city": 28,
                "price": 35
              },
              {
                "id": 716,
                "name": "سلتونة",
                "parent_city": 28,
                "price": 35
              },
              {
                "id": 720,
                "name": "صفصاف",
                "parent_city": 28,
                "price": 35
              },
              {
                "id": 723,
                "name": "الحمامة",
                "parent_city": 28,
                "price": 35
              },
              {
                "id": 726,
                "name": "الحنية",
                "parent_city": 28,
                "price": 35
              },
              {
                "id": 729,
                "name": "الوسيطة",
                "parent_city": 28,
                "price": 35
              },
              {
                "id": 732,
                "name": "قرنادة",
                "parent_city": 28,
                "price": 35
              },
              {
                "id": 735,
                "name": "الفايدية",
                "parent_city": 28,
                "price": 35
              }
            ],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 29,
            "name": "شحات",
            "price": 35,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 30,
            "name": "طبرق",
            "price": 45,
            "branch": 1,
            "locations": [{
              "id": 679,
              "name": "الجغبوب",
              "parent_city": 30,
              "price": 50
            }],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 31,
            "name": "غريان",
            "price": 20,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 32,
            "name": "الاصابعة",
            "price": 30,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 33,
            "name": "الزنتان",
            "price": 45,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 34,
            "name": "جادو",
            "price": 45,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 35,
            "name": "نالوت",
            "price": 45,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 36,
            "name": "براك الشاطئ",
            "price": 35,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 39,
            "name": "هون",
            "price": 35,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 40,
            "name": "السواني",
            "price": 20,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 42,
            "name": "تسليم بفرع حي الاندلس",
            "price": 5,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 44,
            "name": "درنة",
            "price": 35,
            "branch": 1,
            "locations": [{
                "id": 647,
                "name": "الفتائح",
                "parent_city": 44,
                "price": 35
              },
              {
                "id": 649,
                "name": "كرسة",
                "parent_city": 44,
                "price": 35
              },
              {
                "id": 651,
                "name": "وادي الناقة",
                "parent_city": 44,
                "price": 35
              },
              {
                "id": 653,
                "name": "مرتوبة",
                "parent_city": 44,
                "price": 35
              },
              {
                "id": 656,
                "name": "سي خالد",
                "parent_city": 44,
                "price": 35
              },
              {
                "id": 657,
                "name": "حي القاعدة الجوية",
                "parent_city": 44,
                "price": 35
              },
              {
                "id": 659,
                "name": "سيدي عون",
                "parent_city": 44,
                "price": 35
              },
              {
                "id": 661,
                "name": "ام الرزم",
                "parent_city": 44,
                "price": 35
              },
              {
                "id": 663,
                "name": "لاثرون",
                "parent_city": 44,
                "price": 35
              },
              {
                "id": 665,
                "name": "خليج البمبة",
                "parent_city": 44,
                "price": 35
              },
              {
                "id": 667,
                "name": "التميمي",
                "parent_city": 44,
                "price": 45
              },
              {
                "id": 669,
                "name": "العزيات",
                "parent_city": 44,
                "price": 45
              },
              {
                "id": 671,
                "name": "رأس التين",
                "parent_city": 44,
                "price": 45
              }
            ],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 45,
            "name": "قصربن غشير",
            "price": 20,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 48,
            "name": "مطرد",
            "price": 25,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 49,
            "name": "الابيار",
            "price": 35,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 50,
            "name": "وادي الربيع",
            "price": 20,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 52,
            "name": "القلعة",
            "price": 45,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 54,
            "name": "تيجي",
            "price": 45,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 55,
            "name": "أوباري",
            "price": 45,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 56,
            "name": "مرزق",
            "price": 45,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 57,
            "name": "يفرن",
            "price": 45,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 58,
            "name": "طرابلس تسليم نسائي",
            "price": 20,
            "branch": 1,
            "locations": [{
                "id": 527,
                "name": "السياحية",
                "parent_city": 58,
                "price": 20
              },
              {
                "id": 528,
                "name": "حي الأندلس",
                "parent_city": 58,
                "price": 20
              },
              {
                "id": 529,
                "name": "وسط المدينة",
                "parent_city": 58,
                "price": 20
              },
              {
                "id": 530,
                "name": "الظهرة",
                "parent_city": 58,
                "price": 20
              },
              {
                "id": 531,
                "name": "زاوية الدهماني",
                "parent_city": 58,
                "price": 20
              },
              {
                "id": 532,
                "name": "النوفليين",
                "parent_city": 58,
                "price": 20
              },
              {
                "id": 533,
                "name": "بن عاشور",
                "parent_city": 58,
                "price": 20
              },
              {
                "id": 534,
                "name": "شارع الجرابة",
                "parent_city": 58,
                "price": 20
              },
              {
                "id": 535,
                "name": "شارع الزاوية",
                "parent_city": 58,
                "price": 20
              },
              {
                "id": 536,
                "name": "شارع الجمهورية",
                "parent_city": 58,
                "price": 20
              },
              {
                "id": 537,
                "name": "شارع النصر",
                "parent_city": 58,
                "price": 20
              },
              {
                "id": 538,
                "name": "باب بن غشير",
                "parent_city": 58,
                "price": 20
              },
              {
                "id": 539,
                "name": "قرقارش",
                "parent_city": 58,
                "price": 20
              },
              {
                "id": 540,
                "name": "غوط الشعال",
                "parent_city": 58,
                "price": 20
              },
              {
                "id": 541,
                "name": "السراج",
                "parent_city": 58,
                "price": 20
              },
              {
                "id": 542,
                "name": "الدريبي",
                "parent_city": 58,
                "price": 20
              },
              {
                "id": 543,
                "name": "قرجي",
                "parent_city": 58,
                "price": 20
              },
              {
                "id": 544,
                "name": "قدح",
                "parent_city": 58,
                "price": 20
              },
              {
                "id": 545,
                "name": "أبوسليم",
                "parent_city": 58,
                "price": 20
              },
              {
                "id": 546,
                "name": "الرياضية",
                "parent_city": 58,
                "price": 20
              },
              {
                "id": 547,
                "name": "الفلاح",
                "parent_city": 58,
                "price": 20
              },
              {
                "id": 548,
                "name": "غرغور",
                "parent_city": 58,
                "price": 20
              },
              {
                "id": 549,
                "name": "حي الزهور",
                "parent_city": 58,
                "price": 20
              },
              {
                "id": 550,
                "name": "طريق المطار",
                "parent_city": 58,
                "price": 20
              },
              {
                "id": 551,
                "name": "الهضبة",
                "parent_city": 58,
                "price": 20
              },
              {
                "id": 552,
                "name": "صلاح الدين",
                "parent_city": 58,
                "price": 20
              },
              {
                "id": 553,
                "name": "خلة الفرجان",
                "parent_city": 58,
                "price": 20
              },
              {
                "id": 554,
                "name": "طريق الشوك",
                "parent_city": 58,
                "price": 20
              },
              {
                "id": 555,
                "name": "الفرناج",
                "parent_city": 58,
                "price": 20
              },
              {
                "id": 556,
                "name": "الحي الجامعي",
                "parent_city": 58,
                "price": 20
              },
              {
                "id": 557,
                "name": "سيدي المصري",
                "parent_city": 58,
                "price": 20
              },
              {
                "id": 558,
                "name": "راس حسن",
                "parent_city": 58,
                "price": 20
              },
              {
                "id": 559,
                "name": "زناتة",
                "parent_city": 58,
                "price": 20
              },
              {
                "id": 560,
                "name": "جامع الصقع",
                "parent_city": 58,
                "price": 20
              },
              {
                "id": 561,
                "name": "الهاني",
                "parent_city": 58,
                "price": 20
              },
              {
                "id": 562,
                "name": "عرادة",
                "parent_city": 58,
                "price": 20
              },
              {
                "id": 563,
                "name": "الحشان",
                "parent_city": 58,
                "price": 20
              },
              {
                "id": 564,
                "name": "سوق الجمعة",
                "parent_city": 58,
                "price": 20
              },
              {
                "id": 565,
                "name": "كشلاف",
                "parent_city": 58,
                "price": 20
              },
              {
                "id": 566,
                "name": "الغرارات",
                "parent_city": 58,
                "price": 20
              },
              {
                "id": 567,
                "name": "بوستة",
                "parent_city": 58,
                "price": 20
              },
              {
                "id": 568,
                "name": "طريق الشط",
                "parent_city": 58,
                "price": 20
              },
              {
                "id": 569,
                "name": "معيتيقة",
                "parent_city": 58,
                "price": 20
              },
              {
                "id": 570,
                "name": "11 يونيو",
                "parent_city": 58,
                "price": 20
              },
              {
                "id": 571,
                "name": "السبعة",
                "parent_city": 58,
                "price": 20
              },
              {
                "id": 572,
                "name": "طريق المشتل",
                "parent_city": 58,
                "price": 20
              },
              {
                "id": 573,
                "name": "حي دمشق",
                "parent_city": 58,
                "price": 20
              },
              {
                "id": 574,
                "name": "ولي العهد",
                "parent_city": 58,
                "price": 20
              },
              {
                "id": 575,
                "name": "السدرة",
                "parent_city": 58,
                "price": 20
              },
              {
                "id": 576,
                "name": "عين زارة",
                "parent_city": 58,
                "price": 20
              },
              {
                "id": 577,
                "name": "طريق السور",
                "parent_city": 58,
                "price": 20
              },
              {
                "id": 578,
                "name": "بوابة الجبس",
                "parent_city": 58,
                "price": 20
              },
              {
                "id": 579,
                "name": "غابة النصر",
                "parent_city": 58,
                "price": 20
              },
              {
                "id": 580,
                "name": "شارع الصريم",
                "parent_city": 58,
                "price": 20
              }
            ],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 59,
            "name": "غات",
            "price": 50,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 60,
            "name": "الرجبان",
            "price": 45,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 61,
            "name": "السبيعة",
            "price": 25,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 62,
            "name": "الجفرة",
            "price": 35,
            "branch": 1,
            "locations": [{
                "id": 681,
                "name": "زلة",
                "parent_city": 62,
                "price": 35
              },
              {
                "id": 683,
                "name": "سوكنة",
                "parent_city": 62,
                "price": 35
              },
              {
                "id": 685,
                "name": "ودان",
                "parent_city": 62,
                "price": 35
              }
            ],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 63,
            "name": "القبة",
            "price": 35,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 64,
            "name": "ورشفانة الزهراء",
            "price": 20,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 65,
            "name": "ورشفانة الساعدية",
            "price": 20,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 66,
            "name": "ورشفانة العزيزية",
            "price": 20,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 67,
            "name": "بنغازي تسليم نسائي",
            "price": 35,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 68,
            "name": "مصراتة تسليم نسائي",
            "price": 25,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 69,
            "name": "تسليم بفرع الجرابة",
            "price": 5,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 224,
            "name": "تسليم بفرع بنغازي",
            "price": 15,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 319,
            "name": "تسليم بفرع السراج",
            "price": 5,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 321,
            "name": "مسلاتة",
            "price": 20,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 322,
            "name": "تراغن",
            "price": 45,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 324,
            "name": "أم الارانب",
            "price": 45,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 326,
            "name": "سمنو",
            "price": 35,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 328,
            "name": "سوسة",
            "price": 35,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 331,
            "name": "الأبرق",
            "price": 35,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 348,
            "name": "جالو",
            "price": 40,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 350,
            "name": "أوجلة",
            "price": 40,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 352,
            "name": "إجخرة",
            "price": 45,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 354,
            "name": "تازربو",
            "price": 50,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 356,
            "name": "الكفرة",
            "price": 50,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 506,
            "name": "تسليم بفرع مصراتة",
            "price": 10,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 582,
            "name": "غدامس",
            "price": 60,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 585,
            "name": "الجوش",
            "price": 45,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 587,
            "name": "بدر",
            "price": 45,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 589,
            "name": "الرابطة",
            "price": 30,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 591,
            "name": "الرياينة",
            "price": 45,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 593,
            "name": "العوينية",
            "price": 45,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 595,
            "name": "الرحيبات",
            "price": 45,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 597,
            "name": "أولاد محمود",
            "price": 45,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 599,
            "name": "الحرابة",
            "price": 45,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 601,
            "name": "كاباو",
            "price": 45,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 603,
            "name": "طمزين",
            "price": 45,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 605,
            "name": "الحوامد",
            "price": 45,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 607,
            "name": "شكشوك",
            "price": 45,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 609,
            "name": "تكويت",
            "price": 45,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 611,
            "name": "وازن",
            "price": 50,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 613,
            "name": "ككلة",
            "price": 35,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 645,
            "name": "رأس هلال",
            "price": 35,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 687,
            "name": "سوكنة",
            "price": 35,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 689,
            "name": "ودان",
            "price": 35,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 691,
            "name": "زلة",
            "price": 35,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 693,
            "name": "الجغبوب",
            "price": 50,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          },
          {
            "id": 704,
            "name": "تسليم بفرع عين زارة",
            "price": 5,
            "branch": 1,
            "locations": [],
            "branch_region_details": {
              "id": 1,
              "name": "طرابلس",
              "status": 1
            }
          }
        ],
        selectedDonationType: '',
        donationsTypes: [{
            label: 'قيمة مالية',
            value: 1
          },
          {
            label: 'تبرع عيني',
            value: 2
          }
        ],
      };
    },
    methods: {
      getSubCities() {
        this.address_child = null;
        this.address = null;
        this.subCities = this.city.locations;
      },
      post() {
        const x = new FormData();

        x.append("reciever", this.reciever);
        x.append("phone", this.phone);
        x.append("phone_b", this.phone_d);
        x.append("address", this.address);
        x.append("city", this.city.id);
        x.append("address_child", this.address_child);
        x.append("price", this.price);
        x.append("description", this.description);
        x.append("type", this.type);
        x.append("qty", this.qty);
        x.append("leangh", this.leangh);
        x.append("width", this.width);
        x.append("height", this.height);
        x.append("breakable", this.breakable);
        x.append("receipt_money", this.receipt_money);
        x.append("measuring_is_allowed", this.measuring_is_allowed);
        x.append("storage_subscription", this.storage_subscription);
        x.append("inspection_allowed", this.inspection_allowed);
        x.append("heat_intolerance", this.heat_intolerance);
        x.append("casing", this.casing);
        x.append("paid_by", this.paid_by);
        x.append("extra_size_by", this.extra_size_by);
        x.append("commission_by", this.commission_by);
        x.append("save_address", this.save_address);
        x.append("payment_methode", this.payment_methode);
        x.append("package_sub_type", this.package_sub_type);
        x.append("type_id", this.type_id);

        axios({
            method: "post",
            url: "https://app.vanex.ly/api/v1/customer/package",
            data: x,
            headers: {
              Authorization: `Bearer ${this.token}`
            },
          })
          .then(function () {
            swal("تم ارسال طلب التبرع", "شكرا على ارسالك طلب التبرع، سيتواصل معك مندوبنا قريبا لإستلام تبرعاتك", "success");
          })
          .catch(function (error) {
            swal("حدث خطأ", "حدث خطأ الراجاء المحاولة مرة اخرى لاحقاََ", "danger");
            console.log(error);
          });
      },

    },

  }
</script>

<style>

</style>